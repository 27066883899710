export function mapUrlToPublisherName(url) {
    let publisher = '';

    if (url.indexOf('yahoo') >= 0) {
        publisher = 'Yahoo Finance';
    }

    if (url.indexOf('cryptoslate') >= 0) {
        publisher = 'CryptoSlate';
    }

    if (url.indexOf('coingape') >= 0) {
        publisher = 'CoinGape';
    }

    if (url.indexOf('globenewswire') >= 0) {
        publisher = 'GlobeNewswire';
    }

    if (url.indexOf('actionforex') >= 0) {
        publisher = 'ActionForex';
    }

    if (url.indexOf('u.today') >= 0) {
        publisher = 'U.Today';
    }

    if (url.indexOf('cryptodaily') >= 0) {
        publisher = 'CryptoDaily';
    }

    if (url.indexOf('dailyhodl') >= 0) {
        publisher = 'THE DAILY HODL';
    }

    if (url.indexOf('coindesk') >= 0) {
        publisher = 'CoinDesk';
    }

    if (url.indexOf('investorplace') >= 0) {
        publisher = 'INVESTORPLACE';
    }

    if (url.indexOf('trustnodes') >= 0) {
        publisher = 'TRUSTNODES';
    }

    return publisher;
}
