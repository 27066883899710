import React, { useRef } from "react";
import classes from "./modal.module.css";
import { mapUrlToPublisherName } from "../../util/publisherUtils";

import { Modal } from "antd";
import moment from "moment/moment";
const Modall = ({
  isModalOpen,
  setIsModalOpen,
  currentNews,
  setcurrent,
  news,
}) => {
  const ref = useRef();
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const previousHandler = () => {
    document.getElementsByClassName("ant-modal-wrap")[0].scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    setcurrent((prevState) => prevState - 1);
  };
  const nextHandler = () => {
    document.getElementsByClassName("ant-modal-wrap")[0].scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    setcurrent((prevState) => prevState + 1);
  };

  return (
    <Modal
      footer={null}
      title={null}
      open={isModalOpen}
      className={classes.modal}
      onOk={handleOk}
      width={"85%"}
      onCancel={handleCancel}
    >
      {news.length > 0 && (
          <div ref={ref} className={classes.modalContainer}>
              <p className={classes.date}>
                  Published Date:{" "}
                  {moment(news[currentNews || 0].date).format("D MMM YYYY")}
              </p>
              <p className={classes.date}>{mapUrlToPublisherName(news[currentNews].link)}</p>
              <h3 className={classes.title}>{news[currentNews || 0].title}</h3>
              <p className={classes.content}>{news[currentNews || 0].content}</p>
              <div className={classes.days}>
                  {currentNews !== 0 && (
                      <p className={classes.newsDay} onClick={previousHandler}>
                          Previous Article
                      </p>
                  )}
                  {currentNews !== news.length - 1 && (
                      <p className={classes.newsDay} onClick={nextHandler}>
                          Next Article
                      </p>
                  )}
              </div>
        </div>
      )}
    </Modal>
  );
};

export default Modall;
