import { createSlice } from "@reduxjs/toolkit";

export const currentStock = createSlice({
  name: "currentStock",
  initialState: {
    currentStock: [],
    priceValue: {},
    selectedName: "",
    currency: "",
    code: "",
    exchange: "",
  },
  reducers: {
    Add: (state, action) => {
      state.currentStock = action.payload;
    },
    AddPrice: (state, action) => {
      state.priceValue = action.payload;
    },
    AddName: (state, action) => {
      state.selectedName = action.payload;
    },
    AddCode: (state, action) => {
      state.code = action.payload;
    },
    Addexchange: (state, action) => {
      state.exchange = action.payload;
    },
    AddCurrency: (state, action) => {
      state.currency = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { Add, AddPrice, AddCode, AddCurrency, Addexchange, AddName } =
  currentStock.actions;

export default currentStock.reducer;
