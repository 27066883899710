import { uniqueID } from "@syncfusion/ej2-base";
import { Row, Button, Spin } from "antd";
import axios from "axios";
import moment from "moment-timezone";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import Modal from "../../pages/modal/Modal";
import classes from "./News.module.css";
import Financial from "./News/Financial";
import TweetBar from "./News/TweetBar";
import {mapUrlToPublisherName} from "../../util/publisherUtils";
import {useWindowSize} from "../../util/useWindowResize";
import ReactGA from "react-ga4";

const News = ({
  news,
  setnews,
  startDate,
  financialSentiment,
  twitterSentiment,
  endDate,
  setStartDate,
  setTwitterSentiment,
  setfinancialSentiment,
  setEndDate,
  paginate,
  setpaginate,
  newsLoading,
  setnewsLoading,
  newsref,
}) => {
  const selectedName = useSelector((state) => state.currentStock.selectedName);
  const exchange = useSelector((state) => state.currentStock.exchange);
  const code = useSelector((state) => state.currentStock.code);
  const [current, setcurrent] = useState(0);
  const [currentNews, setcurrentNews] = useState({});
  const [height, width] = useWindowSize();
  const [modal, setModal] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const nextDayHandler = async () => {
    setnewsLoading(true);
    setnews([]);
    const start = moment(startDate).add(1, "day").format("YYYY-MM-DD");
    const end = moment(endDate).add(1, "day").format("YYYY-MM-DD");

    const { data } = await axios.get(
      `https://eodhistoricaldata.com/api/news?api_token=${process.env.REACT_APP_API_TOKEN}&s=${code}.${exchange}&offset=0&limit=50&from=${start}&to=${end}`
    );
    const { data: financialData } = await axios.get(
      `https://eodhistoricaldata.com/api/sentiments?s=${code}.${exchange}&from=${start}&to=${end}&api_token=${process.env.REACT_APP_API_TOKEN}`
    );
    const { data: tweetData } = await axios.get(
      `https://eodhistoricaldata.com/api/tweets-sentiments?s=${code}.${exchange}&from=${start}&to=${end}&api_token=${process.env.REACT_APP_API_TOKEN}`
    );
    if (financialData[`${code}.${exchange}`]) {
      const financial =
        financialData[`${code}.${exchange}`][0]?.normalized || -2;
      const modifiedFinancial = (financial).toFixed(2);
      setfinancialSentiment(parseFloat(modifiedFinancial));
    } else {
      setfinancialSentiment(-20);
    }
    if (tweetData[`${code}.${exchange}`]) {
      const tweet = tweetData[`${code}.${exchange}`][0]?.normalized || -2;
      const modifiedtweet = (tweet).toFixed(2);

      setTwitterSentiment(parseFloat(modifiedtweet));
    } else {
      setTwitterSentiment(-20);
    }

    setStartDate(moment(start).format("D MMM YYYY"));
    setEndDate(moment(end).format("D MMM YYYY"));
    let updatedSearchParams = new URLSearchParams(searchParams.toString());
    updatedSearchParams.set("start", start);
    updatedSearchParams.set("end", end);
    setSearchParams(updatedSearchParams.toString());
    setpaginate(10);
    setnews(data);
    setnewsLoading(false);
  };
  const previousDayHandler = async () => {
    setnews([]);
    setnewsLoading(true);
    const start = moment(startDate).subtract(1, "day").format("YYYY-MM-DD");
    const end = moment(endDate).subtract(1, "day").format("YYYY-MM-DD");

    const { data } = await axios.get(
      `https://eodhistoricaldata.com/api/news?api_token=${process.env.REACT_APP_API_TOKEN}&s=${code}.${exchange}&offset=0&limit=50&from=${start}&to=${end}`
    );
    const { data: financialData } = await axios.get(
      `https://eodhistoricaldata.com/api/sentiments?s=${code}.${exchange}&from=${start}&to=${end}&api_token=${process.env.REACT_APP_API_TOKEN}`
    );
    const { data: tweetData } = await axios.get(
      `https://eodhistoricaldata.com/api/tweets-sentiments?s=${code}.${exchange}&from=${start}&to=${end}&api_token=${process.env.REACT_APP_API_TOKEN}`
    );
    if (financialData[`${code}.${exchange}`]) {
      const financial =
        financialData[`${code}.${exchange}`][0]?.normalized || -2;
      const modifiedFinancial = (financial).toFixed(2);
      setfinancialSentiment(parseFloat(modifiedFinancial));
    } else {
      setfinancialSentiment(-20);
    }
    if (tweetData[`${code}.${exchange}`]) {
      const tweet = tweetData[`${code}.${exchange}`][0]?.normalized || -2;
      const modifiedtweet = (tweet).toFixed(2);
      setTwitterSentiment(parseFloat(modifiedtweet));
    } else {
      setTwitterSentiment(-20);
    }
    setStartDate(moment(start).format("D MMM YYYY"));
    setEndDate(moment(end).format("D MMM YYYY"));
    let updatedSearchParams = new URLSearchParams(searchParams.toString());
    updatedSearchParams.set("start", start);
    updatedSearchParams.set("end", end);
    setSearchParams(updatedSearchParams.toString());
    setpaginate(10);
    setnews(data);
    setnewsLoading(false);
  };
  // const singleNewsHandler = (val, index) => {
  //   const data = {
  //     content: val.content,
  //     title: val.title,
  //     date: val.date,
  //     currentIndex: index,
  //   };
  //   setcurrent(index);
  //   setcurrentNews(data);
  //   setModal(true);
  //
  //     // track the modal opened event
  //     ReactGA.event({
  //         'category': 'modal',
  //         'action': 'opened',
  //         'label': `${code}.${exchange}`,
  //         'value': 1,
  //     });
  // };
  const handleNewsItemClick = (e) => {
      // Track link URL
      ReactGA.event({
          'category': 'newsItem',
          'action': 'clicked_link_url',
          'label': e.target.href,
          'value': 1,
      });

      // Track link host
      ReactGA.event({
          'category': 'newsItem',
          'action': 'clicked_link_host',
          'label': e.target.host,
          'value': 1,
      });
  };

  const handleLoadMoreClick = () => {
      setpaginate((prevState) => {
          return prevState + 10;
      });

      // Track link host
      ReactGA.event({
          'category': 'newsItem',
          'action': 'clicked_load_more',
          'label': `${code}.${exchange}`,
          'value': 1,
      });
  };

  const determineSentimentTextColor = (value) => {
      let sentimentTextColor = 'black';

      if (value > 0) {
          sentimentTextColor = 'green';
      } else if (value < 0) {
          sentimentTextColor = 'red';
      }

      return sentimentTextColor;
  };

  return (
      <Row
          ref={newsref}
          className={classes.newsSection}
          style={{ height: `${newsLoading ? "40vh" : "100%"}` }}
      >
          <div className={classes.newsHeader}>
              <p className={classes.newsTitle}>
                  {selectedName.split(" ")[0]} News Published on {startDate}
              </p>
              <div className={classes.days}>
                  <p className={classes.newsDay} onClick={previousDayHandler}>
                      Previous Day
                  </p>
                  <p className={classes.newsDay} onClick={nextDayHandler}>
                      Following Day
                  </p>
              </div>
          </div>
          {!newsLoading &&
          (twitterSentiment !== -20 || financialSentiment !== -20) && (
              <div className={classes.sentimentDiv}>
                  {twitterSentiment !== -20 && (
                      <Row className={classes.sentiment}>
                          <div style={{ fontWeight: "bold" }}>
                              Tweet Sentiment for this day:
                          </div>
                          <TweetBar twitterSentiment={twitterSentiment} />
                      </Row>
                  )}
                  <br />
                  {financialSentiment !== -20 && (
                      <Row className={classes.sentiment}>
                          <div style={{ fontWeight: "bold" }}>
                              News Sentiment for this day:
                          </div>
                          <Financial financialSentiment={financialSentiment} />
                      </Row>
                  )}
              </div>
          )}
          <div
              className={classes.newsList}
              style={{
                  position: "relative",
              }}
          >
              <Modal
                  isModalOpen={modal}
                  setIsModalOpen={setModal}
                  currentNews={current}
                  setcurrent={setcurrent}
                  news={news}
              />
              {newsLoading && <Spin className={classes.spin} size="large" />}
              <table className={classes.newsTable}>
                  <thead style={{ display: width <= 660 && 'none' }}>
                      <tr>
                          <th className={classes.newsNameTitle}>Headline</th>
                          <th className={classes.newsDateTitle}>Publisher</th>
                          <th>Sentiment</th>
                      </tr>
                  </thead>
                  <thead style={{ display: width > 660 && 'none' }}>
                      <tr>
                          <th className={classes.newsNameTitle}>Headlines</th>
                      </tr>
                  </thead>
                  <tbody>
                      {!newsLoading &&
                      (news.length > 0 ? (
                          news?.slice(0, paginate).map((val, i) => {
                              return width > 600 ? (
                                  <tr
                                      key={uniqueID()}
                                      className={classes.singleNews}
                                  >
                                      <td><span className={classes.newsName}><a href={val.link} title={val.title} onClick={handleNewsItemClick} target="_blank">{val.title}</a></span></td>
                                      <td><span>{mapUrlToPublisherName(val.link)}</span></td>
                                      <td><span style={{ color: determineSentimentTextColor(val.sentiment?.polarity) }}>
                                          {val.sentiment?.polarity}
                                        </span></td>
                                  </tr>
                              ) : (
                                  <tr
                                      key={uniqueID()}
                                      className={classes.singleNews}
                                  >
                                      <td>
                                          <span className={classes.newsDate} style={{ display: 'block' }}>
                                            {mapUrlToPublisherName(val.link)}
                                          </span>
                                          <span className={classes.newsName} style={{ display: 'block' }}><a href={val.link} title={val.title} target="_blank">{val.title}</a></span>
                                          <span>Sentiment:</span> <span style={{ color: determineSentimentTextColor(val.sentiment?.polarity) }}>{val.sentiment?.polarity}</span>
                                      </td>
                                  </tr>
                              )

                          })
                      ) : (
                          <tr>
                              <td colSpan="3">
                                <span className={classes.noNews}>No News Found for this Date!</span>
                              </td>
                          </tr>
                      ))}
                  </tbody>
              </table>
          </div>
          <div>
              {news.length >= paginate && news.length > 0 && !newsLoading && (
                  <Button
                      onClick={handleLoadMoreClick}
                      className={classes.loadMore}
                  >
                      Load More
                  </Button>
              )}
          </div>
      </Row>
  );
};

export default News;
