import { IgrLinearGauge, IgrLinearGraphRange } from "igniteui-react-gauges";
import React, { useEffect } from "react";
import { useWindowSize } from "../../../util/useWindowResize";

const Financial = ({ financialSentiment }) => {
  let widthh = "50%";
  const [height, width] = useWindowSize();

  return (
    <IgrLinearGauge
      height="30px"
      width={width > 660 ? widthh : "100%"}
      minimumValue={-1}
      value={financialSentiment}
      needleShape="Custom"
      needleBrush="black"
      needleOutline="black"
      needleStrokeThickness={2}
      needleBreadth={25}
      needleInnerExtent={0.35}
      needleOuterExtent={0.65}
      needleOuterPointExtent={0.8}
      needleInnerPointExtent={0.325}
      needleInnerPointWidth={0}
      needleOuterPointWidth={0.3}
      needleInnerBaseWidth={0}
      needleOuterBaseWidth={0.07}
      backingBrush="#F8F9FA;"
      backingOutline="none"
      backingStrokeThickness={0}
      backingInnerExtent={0}
      backingOuterExtent={0}
      maximumValue={1}
      interval={0.1}
      labelInterval={0.2}
      rangeBrushes="#F83529, #4BE007"
      rangeOutlines="#F83529, #4BE007"
      font="11px Plus Jakarta Sans"
    >
      <IgrLinearGraphRange
        name="range1"
        startValue={-1}
        endValue={0}
        innerStartExtent={0.075}
        innerEndExtent={0.075}
        outerStartExtent={1}
        outerEndExtent={1}
      />
      <IgrLinearGraphRange
        name="range2"
        startValue={0}
        endValue={1}
        innerStartExtent={0.075}
        innerEndExtent={0.075}
        outerStartExtent={1}
        outerEndExtent={1}
      />
    </IgrLinearGauge>
  );
};

export default Financial;
