import { Col, Input, Modal, Row, Spin } from "antd";
import axios from "axios";
import React, { useState } from "react";
import classes from "./home.module.css";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import {
  Add,
  AddCode,
  AddCurrency,
  Addexchange,
  AddName,
  AddPrice,
} from "../store/currentStock";
import Footer from "../Components/Footer";
import { useWindowSize } from "../util/useWindowResize";

const CryptoHome = () => {
  const dispatch = useDispatch();
  const [height, width] = useWindowSize();
  const [result, setresult] = useState([]);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState("");
  let navigate = useNavigate();
  const searchHandler = async (event) => {
    setValue(event.target.value);
    if (event.target.value.trim().length === 0) {
      setresult([]);
    }
    if (event.target.value.trim().length > 0) {
      const { data } = await axios.get(
        `https://eodhistoricaldata.com/api/search/${event.target.value}?api_token=${process.env.REACT_APP_API_TOKEN}&limit=6&type=crypto`
      );
      setresult(data);
    }
  };
  const info = () => {
    Modal.info({
      title: "Can't find news for current crypto",
      content: (
        <div>
          <p>There is currently no news data for current crypto!</p>
        </div>
      ),
      onOk() {},
    });
  };
  const searchStockHandler = async (value) => {
    setLoading(true);
    setValue(value.Name);
    setresult([]);
    const { data } = await axios.get(
      `https://eodhistoricaldata.com/api/eod/${value.Code}.${value.Exchange}?api_token=${process.env.REACT_APP_API_TOKEN}&fmt=json`
    );

    const { data: now } = await axios.get(
      `https://eodhistoricaldata.com/api/eod-bulk-last-day/${value.Exchange}?api_token=${process.env.REACT_APP_API_TOKEN}&symbols=${value.Code}.${value.Exchange}&fmt=json&filter=extended`
    );

    let min_date = moment().subtract(1, "month").format("YYYY-MM-DD");
    const { data: lastMonth } = await axios.get(
      `https://eodhistoricaldata.com/api/eod-bulk-last-day/${value.Exchange}?api_token=${process.env.REACT_APP_API_TOKEN}&symbols=${value.Code}.${value.Exchange}&fmt=json&filter=extended&date=${min_date}
        `
    );
    // const { data: newsData } = await axios.get(
    //   `https://eodhistoricaldata.com/api/news?api_token=${
    //     process.env.REACT_APP_API_TOKEN
    //   }&s=${value.Code}.${value.Exchange}&from=${moment(data[0].date).format("YYYY-MM-DD")}&to=${
    //     new Date(data[0].date) > new Date("2021-03-10")
    //       ? "2022-01-01"
    //       : "2021-03-10"
    //   }&limit=50`
    // );

    if (now.length && lastMonth.length > 0) {
      const todayData = now[0].adjusted_close;
      const lastMonthData = lastMonth[0].adjusted_close;
      const difference = todayData - lastMonthData;
      const percentage = ((todayData - lastMonthData) / lastMonthData) * 100;
      const performance = {
        close: todayData,
        change: difference.toFixed(2),
        percentage: percentage.toFixed(2),
        startDate: null,
        // startDate: moment(newsData[newsData.length - 1].date).format(
        //   "MMM  DD  YYYY"
        // ),
      };
      dispatch(AddPrice(performance));
      dispatch(AddName(value.Name));
      dispatch(AddCode(value.Code));
      dispatch(Addexchange(value.Exchange));
      dispatch(AddCurrency(value.Currency));

      // const tempdate = moment(newsData[newsData.length - 1].date).format(
      //   "DD MMM YYYY"
      // );
      //
      // const dataWithNewsAvailable = data.filter((val) => {
      //   if (moment(val.date) >= new Date(tempdate)) {
      //     const data = {
      //       date: moment(val.date),
      //       currency: value.Currency,
      //       ...val,
      //     };
      //     return data;
      //   }
      // });

      dispatch(Add(data));
      setLoading(false);

        // get date 7 days prior to current date to show news data when the chart first loads
        const start = moment(new Date()).subtract(7, 'days').format("YYYY-MM-DD");
        const end = moment(new Date()).subtract(7, 'days').format("YYYY-MM-DD");

        navigate(
        `/cryptocurrency?code=${value.Code}&exchange=${value.Exchange}&name=${value.Name}&currency=${value.Currency}&interval=1m&start=${start}&end=${end}`
      );
    } else {
      info();
      setLoading(false);
    }
  };
  const [hamB, sethamB] = useState(false);
  const hamburgerHandler = () => {
    sethamB((prevState) => !prevState);
  };
  return (
    <>
      <div className={classes.container}>
        {loading && <Spin className={classes.loadingSpinner} size="large" />}
        <header>
          <nav className={`${hamB && "open"}`}>
            <div onClick={() => sethamB(false)} className="overlay"></div>
            <div className="flex-sm middle">
              <div className="item">
                <Link to={"/"}>
                  <p className={classes.contact}>Stocks</p>
                </Link>
                <Link to={"/crypto"}>
                  <p className={classes.contact}>Crypto</p>
                </Link>
                <Link to={"/contact"}>
                  <p className={classes.contact}>Contact</p>
                </Link>
              </div>
            </div>
          </nav>
        </header>
        <Row className={classes.navbar}>
          <Col className={classes.hamburger} xs={2}>
            <div
              onClick={hamburgerHandler}
              className={`hamburger ${hamB && "open"} flex center`}
              role="button"
              aria-label="Toggle menu"
            >
              <div></div>
            </div>
          </Col>
          <Col className={classes.navlogo}>
            <Link to={"/"}>
              <div>
                <img src="/navlogo.png" alt="" className={classes.logo} />
              </div>
            </Link>
            <div className={classes.listbar}>
              <Link to={"/"}>
                <p className={classes.contact}>Stocks</p>
              </Link>
              <Link to={"/crypto"}>
                <p className={classes.contact}>Crypto</p>
              </Link>
              <Link to={"/contact"}>
                <p className={classes.contact}>Contact</p>
              </Link>
            </div>
          </Col>
        </Row>
        <Row className={classes.main}>
          <div>
            <h1 className={classes.heading}>
              Find correlations between news and cryptocurrency prices
            </h1>
          </div>
          <p className={classes.description}>
            Search a cryptocurrency name or ticker and click a date on the chart
            to see the relevant news from that day
          </p>
          <div className={classes.searchArea}>
            <div className={classes.inputArea}>
              <Input
                onChange={searchHandler}
                value={value}
                className={classes.searchInput}
                placeholder="E.g. Bitcoin or BTC"
              />
              <div className={classes.searchresults}>
                {result?.map((val, index) => {
                  if (width > 1200) {
                    return (
                      <div
                        key={index}
                        className={classes.result}
                        style={{ justifyContent: "space-between" }}
                        onClick={() => searchStockHandler(val)}
                      >
                        <p style={{ width: "18%" }} className={classes.code}>
                          {val.Code}
                        </p>
                        <p className={classes.code}>{val.Exchange}</p>
                        <span className={classes.companyName}>{val.Name}</span>
                      </div>
                    );
                  } else {
                    return (
                      <div
                        key={index}
                        className={classes.result}
                        onClick={() => searchStockHandler(val)}
                      >
                        <p className={classes.code}>{val.Code}</p>
                        <p className={classes.code}>{val.Exchange}</p>
                        <span className={classes.companyName}>{val.Name}</span>
                      </div>
                    );
                  }
                })}
              </div>
            </div>
            {/* <Button loading={loading} className={classes.searchButton}>
            Search Now
          </Button> */}
          </div>
        </Row>
      </div>
      <Footer />
    </>
  );
};

export default CryptoHome;
