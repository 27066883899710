import React from "react";
import "./App.css";
import "antd/dist/antd.min.css";
import Main from "./pages/Main";
import Home from "./pages/Home";
import { Route, Routes } from "react-router-dom";
import Contact from "./pages/Contact";
import { useState } from "react";
import CryptoHome from "./pages/CryptoHome";
import CryptoMain from "./pages/CryptoMain";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Disclaimer from "./pages/Disclaimer";
import Terms from "./pages/Terms";
import ScrollToTop from "./Components/ScrollToTop";
//import ReactGA from "react-ga4";

//ReactGA.initialize("341988650");
// GA is initialised via GTM

function App() {
  return (
    <div>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/crypto" element={<CryptoHome />} />
        <Route path="/stock" element={<Main />} />
        <Route path="/cryptocurrency" element={<CryptoMain />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/disclaimer" element={<Disclaimer />} />
      </Routes>
    </div>
  );
}

export default App;
