import { ShareAltOutlined } from "@ant-design/icons";
import { Button, notification, Row } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import classes from "./TitleBar.module.css";

const TitleBar = ({ loading }) => {
  const [share, setShare] = useState(false);
  const selectedName = useSelector((state) => state.currentStock.selectedName);
  const openNotification = (placement) => {
    notification.info({
      message: `Copied to clipboard`,
      duration: 2,
      description: "Url is copied to clipboard",
      placement,
    });
  };
  function copy() {
    const el = document.createElement("input");
    el.value = window.location.href;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    openNotification("bottom");
  }
  const priceValue = useSelector((state) => state.currentStock.priceValue);
  return (
    <Row className={classes.titleBar}>
      <div className={classes.firstRow}>
        <h1 className={classes.title}>News About {selectedName}</h1>
        <Button
          onClick={copy}
          onMouseEnter={() => setShare(true)}
          onMouseLeave={() => setShare(false)}
          className={classes.shareButton}
          icon={
            <ShareAltOutlined
              className={classes.shareicon}
              style={{ color: `${share ? "#2C70F4" : "white"}` }}
            />
          }
        >
          Share this view
        </Button>
      </div>
      {/*{!loading && (*/}
        {/*<p className={classes.text}>*/}
          {/*Click the chart to select a date and see the news published about{" "}*/}
          {/*{selectedName} on that day: News Available from{" "}*/}
          {/*<span style={{ wordSpacing: "2px" }}>{priceValue.startDate}</span>*/}
        {/*</p>*/}
      {/*)}*/}
    </Row>
  );
};

export default TitleBar;
