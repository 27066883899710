import React, { useState, useRef } from "react";
import classes from "./main.module.css";
import { SearchOutlined } from "@ant-design/icons";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import { Col, Input, Row, Modal as Modl } from "antd";
import moment from "moment/moment";
import axios from "axios";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Add,
  AddCode,
  AddCurrency,
  Addexchange,
  AddName,
  AddPrice,
} from "../store/currentStock";
import TitleBar from "../Components/Main/TitleBar";
import Chart from "../Components/Main/Chart";
import News from "../Components/Main/News";
import Footer from "../Components/Footer";
import { useWindowSize } from "../util/useWindowResize";

const SAMPLE_CSS = `
.control-fluid {
    padding: 0px !important;
}
    .charts {
        align :center
    }`;

const Main = () => {
  const [startDate, setStartDate] = useState("");
  const [financialSentiment, setfinancialSentiment] = useState(0);
  const [twitterSentiment, setTwitterSentiment] = useState(0);
  const [endDate, setEndDate] = useState("");
  const [height, width] = useWindowSize();
  const [paginate, setpaginate] = useState(10);
  const [newsLoading, setnewsLoading] = useState(false);
  const [event, setevent] = useState(30);
  const [hamB, sethamB] = useState(false);
  const hamburgerHandler = () => {
    sethamB((prevState) => !prevState);
  };
  const dispatch = useDispatch();
  // const [endDate, setEndDate] = useState("");
  const [news, setnews] = useState([]);
  const [result, setresult] = useState([]);
  const [loading, setLoading] = useState(false);
  const [value, setvalue] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const stockData = useSelector((state) => state.currentStock.currentStock);
  const navigate = useNavigate();
  useEffect(() => {
    /*
    When page first loads:
    - it will already have stock data saved in store from home page (or search form)
     */
    const getData = async () => {
      if (stockData.length > 0) {
        return;
      }
      const exchange = searchParams.get("exchange");
      const code = searchParams.get("code");
      const name = searchParams.get("name");
      const start = searchParams.get("start");
      const end = searchParams.get("end");
      const currency = searchParams.get("currency");
      if (!code || !exchange || !name) {
        navigate("/");
      }
      setevent(30);
      setvalue(name);
      setLoading(true);

      const { data } = await axios.get(
        `https://eodhistoricaldata.com/api/eod/${code}.${exchange}?api_token=${process.env.REACT_APP_API_TOKEN}&fmt=json`
      );
      const { data: now } = await axios.get(
        `https://eodhistoricaldata.com/api/eod-bulk-last-day/${exchange}?api_token=${process.env.REACT_APP_API_TOKEN}&symbols=${code}.${exchange}&fmt=json&filter=extended`
      );
      let min_date = moment().subtract(1, "month").format("YYYY-MM-DD");
      const { data: lastMonth } = await axios.get(
        `https://eodhistoricaldata.com/api/eod-bulk-last-day/${exchange}?api_token=${process.env.REACT_APP_API_TOKEN}&symbols=${code}.${exchange}&fmt=json&filter=extended&date=${min_date}
        `
      );
      // TODO - see if we still need this
      // this only returns 10 items and ignores limit anyway
      // const { data: newsData } = await axios.get(
      //   `https://eodhistoricaldata.com/api/news?api_token=${
      //     process.env.REACT_APP_API_TOKEN
      //   }&s=${code}.${exchange}&from=${moment(data[0].date).format("YYYY-MM-DD")}&to=${
      //     new Date(data[0].date) > new Date("2021-03-10")
      //       ? "2022-01-01"
      //       : "2021-03-10"
      //   }&limit=1000`
      // );
      if (now.length && lastMonth.length > 0) {
        const todayData = now[0].adjusted_close;
        const lastMonthData = lastMonth[0].adjusted_close;
        const difference = todayData - lastMonthData;
        const percentage = ((todayData - lastMonthData) / lastMonthData) * 100;
        const performance = {
          close: todayData,
          change: difference.toFixed(2),
          percentage: percentage.toFixed(2),
          startDate: null,
          // startDate: moment(newsData[newsData.length - 1].date).format(
          //   "MMM  DD  YYYY"
          // ),
        };
        dispatch(AddPrice(performance));
        dispatch(AddName(name));
        dispatch(AddCode(code));
        dispatch(Addexchange(exchange));
        dispatch(AddCurrency(currency));
        // TODO - work out why this was being used and see if we still need it
        // It's been filtered to make sure only date with news items are used.
        // const tempdate = moment(newsData[newsData.length - 1].date).format(
        //   "DD MMM YYYY"
        // );
        //
        // // why is this being filtered? - see above
        // const dataWithNewsAvailable = data.filter((val) => {
        //   if (moment(val.date) >= new Date(tempdate)) {
        //     const data = {
        //       date: moment(val.date),
        //       ...val,
        //     };
        //     return data;
        //   }
        // });

        dispatch(Add(data));
        setLoading(false);
      } else {
        info();
        setLoading(false);
      }
    };

    getData();
  });

    useEffect(() => {
        const getNewsData = async () => {
            const exchange = searchParams.get("exchange");
            const code = searchParams.get("code");
            const start = searchParams.get("start");
            const end = searchParams.get("end");

            // news get api
            if (start !== "null" && end !== "null") {
                setnewsLoading(true);
                const {data} = await axios.get(
                    `https://eodhistoricaldata.com/api/news?api_token=${process.env.REACT_APP_API_TOKEN}&s=${code}.${exchange}&offset=0&limit=50&from=${start}&to=${end}`
                );
                const { data: financialData } = await axios.get(
                    `https://eodhistoricaldata.com/api/sentiments?s=${code}.${exchange}&from=${start}&to=${end}&api_token=${process.env.REACT_APP_API_TOKEN}`
                );
                const { data: tweetData } = await axios.get(
                    `https://eodhistoricaldata.com/api/tweets-sentiments?s=${code}.${exchange}&from=${start}&to=${end}&api_token=${process.env.REACT_APP_API_TOKEN}`
                );
                if (financialData[`${code}.${exchange}`]) {
                    const financial =
                        financialData[`${code}.${exchange}`][0]?.normalized || -2;
                    const modifiedFinancial = (financial).toFixed(2);
                    setfinancialSentiment(parseFloat(modifiedFinancial));
                } else {
                    setfinancialSentiment(-20);
                }
                if (tweetData[`${code}.${exchange}`]) {
                    const tweet = tweetData[`${code}.${exchange}`][0]?.normalized || -2;
                    const modifiedtweet = (tweet).toFixed(2);

                    setTwitterSentiment(parseFloat(modifiedtweet));
                } else {
                    setTwitterSentiment(-20);
                }

                //setStartDate(moment(start).format("D MMM YYYY"));
                //setEndDate(moment(end).format("D MMM YYYY"));
                setpaginate(10);
                setnews(data);
                setnewsLoading(false);
            }

        };

        getNewsData();
    }, [searchParams]);

  const searchHandler = async (event) => {
    setvalue(event.target.value);
    if (event.target.value.trim().length === 0) {
      setresult([]);
    }
    if (event.target.value.trim().length > 0) {
      const { data } = await axios.get(
        `https://eodhistoricaldata.com/api/search/${event.target.value}?api_token=${process.env.REACT_APP_API_TOKEN}&limit=6&type=stock`
      );

      setresult(data);
    }
  };
  const info = () => {
    Modl.info({
      title: "Can't find news for current stock",
      content: (
        <div>
          <p>There is currently no news data for current stock!</p>
        </div>
      ),
      onOk() {},
    });
  };
  // Search stock function
  const searchStockHandler = async (value) => {
    setnews([]);
    setevent(30);
    setnews(1);

    setLoading(true);
    setresult([]);
    setvalue(value.Name);
    let min_date = moment().subtract(1, "month").format("YYYY-MM-DD");
    const { data } = await axios.get(
      `https://eodhistoricaldata.com/api/eod/${value.Code}.${value.Exchange}?api_token=${process.env.REACT_APP_API_TOKEN}&fmt=json`
    );
    const { data: now } = await axios.get(
      `https://eodhistoricaldata.com/api/eod-bulk-last-day/${value.Exchange}?api_token=${process.env.REACT_APP_API_TOKEN}&symbols=${value.Code}.${value.Exchange}&fmt=json&filter=extended`
    );
    const { data: lastMonth } = await axios.get(
      `https://eodhistoricaldata.com/api/eod-bulk-last-day/${value.Exchange}?api_token=${process.env.REACT_APP_API_TOKEN}&symbols=${value.Code}.${value.Exchange}&fmt=json&filter=extended&date=${min_date}
      `
    );
    // const { data: newsData } = await axios.get(
    //   `https://eodhistoricaldata.com/api/news?api_token=${
    //     process.env.REACT_APP_API_TOKEN
    //   }&s=${value.Code}.${value.Exchange}&from=${moment(data[0].date).format("YYYY-MM-DD")}&to=${
    //     new Date(data[0].date) > new Date("2021-03-10")
    //       ? "2022-01-01"
    //       : "2021-03-10"
    //   }&limit=1000`
    // );

    if (now.length && lastMonth.length > 0) {
      const todayData = now[0].adjusted_close;
      const lastMonthData = lastMonth[0].adjusted_close;
      const difference = todayData - lastMonthData;
      const percentage = ((todayData - lastMonthData) / lastMonthData) * 100;
      const performance = {
        close: todayData,
        change: difference.toFixed(2),
        percentage: percentage.toFixed(2),
        startDate: null,
        // startDate: moment(newsData[newsData.length - 1].date).format(
        //   "MMM DD YYYY"
        // ),
      };
      // const tempdate = moment(newsData[newsData.length - 1].date).format(
      //   "DD MMM YYYY"
      // );

      dispatch(AddPrice(performance));
      dispatch(AddName(value.Name));
      dispatch(AddCode(value.Code));
      dispatch(Addexchange(value.Exchange));
      dispatch(AddCurrency(value.Currency));
      // only show data where we have news for it
      // const dataWithNewsAvailable = data.filter((val) => {
      //   if (moment(val.date) >= new Date(tempdate)) {
      //     const data = {
      //       date: moment(val.date),
      //       ...val,
      //     };
      //     return data;
      //   }
      // });

      // get date 7 days prior to current date to show news data when the chart first loads
      const initialStart = moment(new Date()).subtract(7, 'days').format("YYYY-MM-DD");
      const initialEnd = moment(new Date()).subtract(7, 'days').format("YYYY-MM-DD");

      let updatedSearchParams = new URLSearchParams(searchParams.toString());
      updatedSearchParams.set("interval", "1m");
      updatedSearchParams.set("name", value.Name);
      updatedSearchParams.set("code", value.Code);
      updatedSearchParams.set("exchange", value.Exchange);
      updatedSearchParams.set("currency", value.Currency);
      updatedSearchParams.set("start", initialStart);
      updatedSearchParams.set("end", initialEnd);

      setSearchParams(updatedSearchParams.toString());
      dispatch(Add(data));

      setLoading(false);
    } else {
      info();
      setvalue("");
      setLoading(false);
    }
  };

  const newsref = useRef(null);
  const content = (
    <Row style={{ width: "35vw", backgroundColor: "#f0f0f0" }}>
      <>
        <Col xs={24} style={{ margin: "4px 4px" }}>
          <Link to={"/contact"}>
            <p className={classes.contact}>Contact</p>
          </Link>
        </Col>
      </>
    </Row>
  );

  return (
    <>
      <div
        className={classes.container}
        style={{ overflowX: "hidden", height: `${loading ? "100vh" : "100%"}` }}
      >
        <style>{SAMPLE_CSS}</style>
        <header>
          <nav className={`${hamB && "open"}`}>
            <div onClick={() => sethamB(false)} className="overlay"></div>
            <div className="flex-sm middle">
              <div className="item">
                <Link to={"/"}>
                  <p className={classes.contact}>Stocks</p>
                </Link>
                <Link to={"/crypto"}>
                  <p className={classes.contact}>Crypto</p>
                </Link>
                <Link to={"/contact"}>
                  <p className={classes.contact}>Contact</p>
                </Link>
              </div>
            </div>
          </nav>
        </header>
        <Row className={classes.navbar}>
          <Col className={classes.hamburger} xs={2}>
            <div
              onClick={hamburgerHandler}
              className={`hamburger ${hamB && "open"} flex center`}
              role="button"
              aria-label="Toggle menu"
            >
              <div></div>
            </div>
          </Col>
          <Col xs={22} lg={10} className={classes.navlogo}>
            <Link to={"/"}>
              <div>
                <img src="/navlogo.png" alt="" className={classes.logo} />
              </div>
            </Link>
          </Col>

          <Col xs={24} lg={7} className={classes.searchArea}>
            <div className={classes.inputArea}>
              <Input
                prefix={<SearchOutlined />}
                value={value}
                onChange={searchHandler}
                className={classes.searchInput}
                placeholder="E.g. Tesla or TSLA"
              />
              <div className={classes.searchresults}>
                {result?.map((val, index) => {
                  if (width > 650) {
                    return (
                      <div
                        key={index}
                        className={classes.result}
                        onClick={() => searchStockHandler(val)}
                      >
                        <p className={classes.code}>{val.Code}</p>
                        <p className={classes.code}>{val.Exchange}</p>
                        <span className={classes.companyName}>{val.Name}</span>
                        <p className={classes.code}>{val.Country}</p>
                        <p>${val.previousClose}</p>
                        <p className={classes.code}>{val.Currency}</p>
                      </div>
                    );
                  } else {
                    return (
                      <div
                        key={index}
                        className={classes.result}
                        onClick={() => searchStockHandler(val)}
                      >
                        <p className={classes.code}>{val.Code}</p>
                        <p className={classes.code}>{val.Exchange}</p>
                        <span className={classes.companyName}>{val.Name}</span>
                        <p className={classes.code}>{val.Country}</p>
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          </Col>
          <Col lg={1}></Col>
          <Col lg={6} className={classes.contactdiv}>
            <Link to={"/"}>
              <p className={classes.contact}>Stocks</p>
            </Link>
            <Link to={"/crypto"}>
              <p className={classes.contact}>Crypto</p>
            </Link>
            <Link to={"/contact"}>
              <p className={classes.contact}>Contact</p>
            </Link>
          </Col>
        </Row>
        <TitleBar loading={loading} />
        <Chart
          loading={loading}
          setEndDate={setEndDate}
          setStartDate={setStartDate}
          setnews={setnews}
          setfinancialSentiment={setfinancialSentiment}
          setTwitterSentiment={setTwitterSentiment}
          setpaginate={setpaginate}
          setnewsLoading={setnewsLoading}
          newsref={newsref}
          event={event}
          setevent={setevent}
        />
        {news !== 1 && (
          <News
            news={news}
            financialSentiment={financialSentiment}
            twitterSentiment={twitterSentiment}
            setnews={setnews}
            setfinancialSentiment={setfinancialSentiment}
            setTwitterSentiment={setTwitterSentiment}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            newsLoading={newsLoading}
            setnewsLoading={setnewsLoading}
            setpaginate={setpaginate}
            paginate={paginate}
            newsref={newsref}
          />
        )}
      </div>
      <Footer />
    </>
  );
};

export default Main;
