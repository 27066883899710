import { Col, Row } from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../Components/Footer";
import classes from "./home.module.css";

const PrivacyPolicy = () => {
  const [hamB, sethamB] = useState(false);
  const hamburgerHandler = () => {
    sethamB((prevState) => !prevState);
  };
  return (
    <>
      <header>
        <nav className={`${hamB && "open"}`}>
          <div onClick={() => sethamB(false)} className="overlay"></div>
          <div className="flex-sm middle">
            <div className="item">
              <Link to={"/"}>
                <p className={classes.contact}>Stocks</p>
              </Link>
              <Link to={"/crypto"}>
                <p className={classes.contact}>Crypto</p>
              </Link>
              <Link to={"/contact"}>
                <p className={classes.contact}>Contact</p>
              </Link>
            </div>
          </div>
        </nav>
      </header>
      <Row className={classes.navbar}>
        <Col className={classes.hamburger} xs={2}>
          <div
            onClick={hamburgerHandler}
            className={`hamburger ${hamB && "open"} flex center`}
            role="button"
            aria-label="Toggle menu"
          >
            <div></div>
          </div>
        </Col>
        <Col className={classes.navlogo}>
          <Link to={"/"}>
            <div>
              <img src="/navlogo.png" alt="" className={classes.logo} />
            </div>
          </Link>
          <div className={classes.listbar}>
            <Link to={"/"}>
              <p className={classes.contact}>Stocks</p>
            </Link>
            <Link to={"/crypto"}>
              <p className={classes.contact}>Crypto</p>
            </Link>
            <Link to={"/contact"}>
              <p className={classes.contact}>Contact</p>
            </Link>
          </div>
        </Col>
      </Row>
      <Row className={classes.privacyMain}>
        <h1>Disclaimer for Price Historian</h1>
          <h2>Price Historian is not providing investment advice</h2>
          <p>Past performance is not indicative of future results. Price Historian displays chart data and sentiment analysis from 3rd parties and is for informational purposes only.</p>
          <p>Nothing on this website should be considered investment or financial advice.</p>
      </Row>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
