import React, {useEffect, useState, useRef} from "react";
import classes from "./Chart.module.css";
import { InfoCircleTwoTone } from "@ant-design/icons";
import ReactGA from "react-ga4";
import {
  StockChartComponent,
  StockChartSeriesCollectionDirective,
  StockChartSeriesDirective,
  Inject,
  DateTime,
  Tooltip,
  RangeTooltip,
  Crosshair,
  LineSeries,
  SplineSeries,
  CandleSeries,
  HiloOpenCloseSeries,
  HiloSeries,
  RangeAreaSeries,
  Trendlines,
  StockChartAxesDirective,
  StockChartAxisDirective,
  Legend,
  DataLabel,
  AreaSeries,
  Zoom,
  StripLine
} from "@syncfusion/ej2-react-charts";
import {
  EmaIndicator,
  RsiIndicator,
  BollingerBands,
  TmaIndicator,
  MomentumIndicator,
  SmaIndicator,
  AtrIndicator,
  AccumulationDistributionIndicator,
  MacdIndicator,
  StochasticIndicator,
  Export,
} from "@syncfusion/ej2-react-charts";
import { useDispatch, useSelector } from "react-redux";
import { Row, Spin } from "antd";
import moment from "moment/moment";
import axios from "axios";
import { AddPrice } from "../../store/currentStock";
import { useWindowSize } from "../../util/useWindowResize";
import { useSearchParams } from "react-router-dom";

const Chart = ({
  loading,
  setnewsLoading,
  setStartDate,
  setfinancialSentiment,
  setTwitterSentiment,
  setEndDate,
  setpaginate,
  setnews,
  newsref,
  event,
  setevent,
}) => {
    const chartComponent = useRef(null);
  const [firstnewsLoading, setfirstnewsLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const initialEnd = searchParams.get("end");
  const dispatch = useDispatch();
  const stockData = useSelector((state) => state.currentStock.currentStock);
  const priceValue = useSelector((state) => state.currentStock.priceValue);
  const code = useSelector((state) => state.currentStock.code);
  const exchange = useSelector((state) => state.currentStock.exchange);
  const currency = useSelector((state) => state.currentStock.currency);

  const intervalValue = searchParams.get("interval");
    const initialStart = searchParams.get("start");

    const pointClickHandler = async (value) => {
        setfirstnewsLoading(true);
        setnewsLoading(true);

        const start = moment(value.point.x).format("YYYY-MM-DD");
        // don't need to add a day here because we want news from the date clicked only
        const end = moment(value.point.x).format("YYYY-MM-DD");

        // TODO - add better load more functionality to deal with the news count being reduced to 50 from 1000
        const {data} = await axios.get(
            `https://eodhistoricaldata.com/api/news?api_token=${process.env.REACT_APP_API_TOKEN}&s=${code}.${exchange}&offset=0&limit=50&from=${start}&to=${end}`
        );
        const {data: financialData} = await axios.get(
            `https://eodhistoricaldata.com/api/sentiments?s=${code}.${exchange}&from=${start}&to=${end}&api_token=${process.env.REACT_APP_API_TOKEN}`
        );
        const {data: tweetData} = await axios.get(
            `https://eodhistoricaldata.com/api/tweets-sentiments?s=${code}.${exchange}&from=${start}&to=${end}&api_token=${process.env.REACT_APP_API_TOKEN}`
        );
        if (financialData[`${code}.${exchange}`]) {
            const financial =
                financialData[`${code}.${exchange}`][0]?.normalized || -2;
            const modifiedFinancial = (financial).toFixed(2);
            setfinancialSentiment(parseFloat(modifiedFinancial));
        } else {
            setfinancialSentiment(-20);
        }
        if (tweetData[`${code}.${exchange}`]) {
            const tweet = tweetData[`${code}.${exchange}`][0]?.normalized || -2;
            const modifiedtweet = (tweet).toFixed(2);

            setTwitterSentiment(parseFloat(modifiedtweet));
        } else {
            // TODO - why is it doing this? - to show there are no results
            setTwitterSentiment(-20);
        }

        // track the date selected event
        ReactGA.event({
            'category': 'chart',
            'action': 'date_selected',
            'label': moment(value.point.x).format("YYYY-MM-DD"),
        });

        // track the news loaded event
        ReactGA.event({
            'category': 'chart',
            'action': 'news_items_loaded_on_date_click',
            'label': `${code}.${exchange}`,
            'value': data.length,
        });

        setStartDate(moment(start).format("D MMM YYYY"));
        setEndDate(moment(end).format("D MMM YYYY"));

        let updatedSearchParams = new URLSearchParams(searchParams.toString());
        updatedSearchParams.set("start", start);
        updatedSearchParams.set("end", end);
        setSearchParams(updatedSearchParams.toString());
        setpaginate(10);
        setnews(data);

        newsref.current?.scrollIntoView({behavior: "smooth"});

        setfirstnewsLoading(false);
        setnewsLoading(false);
        chartComponent.current.refresh();
    };

  const changeIt = async (val, date, interv) => {
    setevent(val);
    let updatedSearchParams = new URLSearchParams(searchParams.toString());
    updatedSearchParams.set("interval", interv);
    setSearchParams(updatedSearchParams.toString());

    const { data: lastDate } = await axios.get(
      `https://eodhistoricaldata.com/api/eod-bulk-last-day/${exchange}?api_token=${process.env.REACT_APP_API_TOKEN}&symbols=${code}.${exchange}&fmt=json&filter=extended&date=${date}
        `
    );
    const todayData = priceValue.close;

    const lastDateData = lastDate[0].adjusted_close;

    const difference = todayData - lastDateData;
    const percentage = ((todayData - lastDateData) / lastDateData) * 100;

    const performance = {
      ...priceValue,
      close: todayData,
      change: difference.toFixed(2),
      percentage: percentage.toFixed(2),
    };
    dispatch(AddPrice(performance));
  };
  const loadedHandler = () => {
    const data = document.querySelector('[aria-label="5Y"]');
    const fiveDay = document.querySelector('[aria-label="5D"]');
    const month = document.querySelector('[aria-label="1M"]');
    const year = document.querySelector('[aria-label="1Y"]');
    const sixmonth = document.querySelector('[aria-label="6M"]');
    const ytd = document.querySelector('[aria-label="YTD"]');
    const max = document.querySelector('[aria-label="Max"]');
    if (!!data) {
      let min_date = moment().subtract(5, "years").format("YYYY-MM-DD");
      data.onclick = () => changeIt(365, min_date, "5y");
    }
    if (!!month) {
      let min_date = moment().subtract(1, "months").format("YYYY-MM-DD");
      month.onclick = () => changeIt(30, min_date, "1m");
    }
    if (!!year) {
      let min_date = moment().subtract(1, "years").format("YYYY-MM-DD");
      year.onclick = () => changeIt(60, min_date, "1y");
    }
    if (!!sixmonth) {
      let min_date = moment().subtract(6, "months").format("YYYY-MM-DD");
      sixmonth.onclick = () => changeIt(60, min_date, "6m");
    }
    if (!!ytd) {
      let min_date = moment().startOf("year").format("YYYY-MM-DD");

      ytd.onclick = () => changeIt(30, min_date, "ytd");
    }
    if (!!fiveDay) {
      let min_date = moment().subtract(5, "days").format("YYYY-MM-DD");

      fiveDay.onclick = () => changeIt(1, min_date, "5d");
    }
    if (!!max) {
      max.onclick = () => changeIt(720, stockData[0].date, "max");
    }
  };
  const [height, width] = useWindowSize();

  const webView =
    // eslint-disable-next-line
    "<b>High : $${point.high}</b> " +
    currency +
    "<br />" +
    "<b>Low : $${point.low}</b> " +
    currency +
    "<br />" +
    "<b>Open : $${point.open}</b> " +
    currency +
    "<br />" +
    "<b>Close : $${point.close}</b> " +
    currency +
    "<br />" +
    "<b>Volume : $${point.volume}</b> " +
    currency;
    const mobileView =
        // eslint-disable-next-line
        "<b>H : $${point.high}</b> " +
        currency +
        "<br />" +
        "<b>L : $${point.low}</b> " +
        currency +
        "<br />" +
        "<b>O : $${point.open}</b> " +
        currency +
        "<br />" +
        "<b>C : $${point.close}</b> " +
        currency +
        "<br />" +
        "<b>V : $${point.volume}</b> " +
        currency;
  const webcolor = "#2C70F4";
  //const mobileView = "<b>${point.close} </b>" + currency;
  const mobileColor = "#4F4F4F";
  const marker = { visible: false, height: width > 660 ? 10 : 12, width: width > 660 ? 10 : 12, shape: 'Circle' };
  // if there is no marker on the initialEnd date it skips to the next available date
  const stockEvents = [
        { date: moment(initialEnd).format("D MMM YYYY"),
            placeAt: 'close', showOnSeries: true, text: '', description: 'Selected Date',
            type: 'Circle', background: '#ffffff', border: { color: '#FB2121', width: 2 } }
    ];

  return (
    <>
      {loading && <Spin className={classes.spin} size="large" />}
      <div className={classes.stockChart} style={{ position: "relative" }}>
        {firstnewsLoading && <Spin className={classes.spin} size="large" />}
        {!loading && (
          <>
            <Row className={classes.currentPriceRow}>
              <h1 className={classes.currentPrice}>
                ${priceValue.close?.toFixed(2)} {currency}
              </h1>{" "}
              <div style={{ display: "flex", gap: "1rem" }}>
                <div
                  className={
                    priceValue.percentage < 0
                      ? classes.changePercentageNegative
                      : classes.changePercentagePositive
                  }
                >
                  {priceValue.percentage} %
                </div>
                <div
                  className={
                    priceValue.change < 0
                      ? classes.changePriceNegative
                      : classes.changePercentagePositive
                  }
                >
                  {priceValue.change} {intervalValue?.toUpperCase()}
                </div>
              </div>
            </Row>
            <Row className={classes.chartHeading}>
              <h2 className={classes.chartHeadingPanel}><InfoCircleTwoTone /> Click a point on the chart to view news for that date</h2>
            </Row>
            {/*<Row className={classes.currentPriceRow}>*/}
              {/*<h3>*/}
                {/*News Available from{"  "}*/}
                {/*<span style={{ wordSpacing: "2px" }}>*/}
                  {/*{ priceValue.startDate}*/}
                {/*</span>*/}
              {/*</h3>*/}
            {/*</Row>*/}
          </>
        )}
        {!loading && priceValue && (
          <StockChartComponent
            background="#F8F9FA"
            id="stockchartspline"
            ref={chartComponent}
            loaded={loadedHandler}
            //stockEventRender={stockEventHandler}
            stockEvents={stockEvents}
            seriesRender={loadedHandler}
            series
            primaryXAxis={{
              valueType: "DateTime",
              //interval: event,
              labelFormat: "MMM dd, yy",
              majorGridLines: { color: "#979797", width: 1 },
              minorGridLines: { color: "transparent" },
              crosshairTooltip: { enable: true },
                //majorGridLines: { width: 0 },
              majorTickLines: { color: 'transparent' }
            }}
            primaryYAxis={{
              opposedPosition: false,
              lineStyle: { color: "transparent" },
              majorTickLines: { color: "transparent", width: 0 },
              majorGridLines: { color: "transparent", width: 0 },
          }}
            zoomSettings={{
                enableMouseWheelZooming: false,
                enablePinchZooming: true,
                enableSelectionZooming: true,
                toolbarItems: ['ZoomIn', 'ZoomOut', 'Reset'],
                showToolbar: true,
                enablePan: false,
                mode: 'X',
            }}
            indicatorType={[]}
            periods={[
              {
                text: "5D",
                interval: 5,
                intervalType: "Days",
                selected: intervalValue === "5d" ? true : false,
              },
              {
                text: "1M",
                interval: 1,
                intervalType: "Months",
                selected: intervalValue === "1m" ? true : false,
              },

              {
                text: "6M",
                interval: 6,
                intervalType: "Months",
                selected: intervalValue === "6m" ? true : false,
              },
              { text: "YTD", selected: intervalValue === "ytd" ? true : false },
              {
                text: "1Y",
                interval: 1,
                intervalType: " Days Years",
                selected: intervalValue === "1y" ? true : false,
              },
              {
                text: "5Y",
                interval: 5,
                intervalType: "Years",
                selected: intervalValue === "5y" ? true : false,
              },
              {
                text: "Max",
                interval: 10,
                intervalType: "Years",
                selected: intervalValue === "max" ? true : false,
              },
            ]}
            titleStyle={{ background: "red" }}
            seriesType={[]}
            pointClick={pointClickHandler}
            trendlineType={false}
            chartArea={{
              border: { width: 4, color: "#F8F9FA" },
              background: "#F8F9FA",
            }}
            tooltip={{
              enable: true,
              header: "<b>${point.x}</b>",
              format: width > 660 ? webView : mobileView,
              fill: width > 660 ? webcolor : mobileColor,
              border: "none",
              opacity: 0.8,
              enableAnimation: true,
              fadeOutDuration: 300,
              duration: 200,
            }}
            enableSelector={false}
            crosshair={{ enable: true }}
          >
            <Inject
              services={[
                DateTime,
                Tooltip,
                RangeTooltip,
                Crosshair,
                LineSeries,
                SplineSeries,
                AreaSeries,
                Legend,
                Tooltip,
                DataLabel,
                Zoom,
                DateTime,
                // StripLine,
                // CandleSeries,
                // HiloOpenCloseSeries,
                // HiloSeries,
                // RangeAreaSeries,
                // Trendlines,
                // EmaIndicator,
                // RsiIndicator,
                // BollingerBands,
                // TmaIndicator,
                // MomentumIndicator,
                // SmaIndicator,
                // AtrIndicator,
                // Export,
                // AccumulationDistributionIndicator,
                // MacdIndicator,
                // StochasticIndicator,
              ]}
            />
            <StockChartAxesDirective>
              <StockChartAxisDirective
                rowIndex={0}
                name="close"
                lineStyle={{ color: "transparent" }}
                majorTickLines={{ color: "transparent", width: 0 }}
                majorGridLines={{ color: "transparent", width: 0 }}
              ></StockChartAxisDirective>
            </StockChartAxesDirective>
            <StockChartSeriesCollectionDirective>
              <StockChartSeriesDirective
                dataSource={stockData ? stockData : []}
                xName="date"
                yName="close"
                yAxisName="close"
                type="Spline"
                fill="red"
                width={2}
                marker={marker}
              ></StockChartSeriesDirective>
            </StockChartSeriesCollectionDirective>
          </StockChartComponent>
        )}
      </div>
    </>
  );
};

export default Chart;
