import { RedditCircleFilled, TwitterOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import classes from "./Footer.module.css";

const Footer = () => {
  return (
    <footer className={classes.footer}>
      <div className={classes.innerDiv}>
        <div className={classes.iconDiv}>
          <div>
            <a
              href={"https://www.reddit.com/user/PriceHistorian"}
              target="_blank"
            >
              <RedditCircleFilled className={classes.twitter} />
            </a>
          </div>
          <div>
            <a href={"https://twitter.com/pricehistorian"} target="_blank">
              <TwitterOutlined className={classes.twitter} />
            </a>
          </div>
        </div>
        <div>
          <p className={classes.text}>I hope you’re loving Price Historian.</p>
        </div>

        <div className={classes.lowerLinks}>
          <Link to={"/"}>
            <span>Stocks</span>
          </Link>
          <Link to={"/crypto"}>
            <span>Crpyto</span>
          </Link>
          <Link to={"/contact"}>
            <span>Contact</span>
          </Link>
        </div>
        <div className={classes.lowsesLinks}>
          <Link to={"/terms"}>
            <span>Terms and Conditions</span>
          </Link>
          <Link to={"/privacy-policy"}>
            <span>Privacy Policy</span>
          </Link>
            <Link to={"/disclaimer"}>
            <span>Disclaimer</span>
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
