import React, { useRef, useState } from "react";
import classes from "./contact.module.css";
import { Link } from "react-router-dom";
import { Button, Col, Input, Modal, Row } from "antd";
import TextArea from "antd/lib/input/TextArea";
import Footer from "../Components/Footer";
import emailjs from "@emailjs/browser";
const Contact = () => {
  const [hamB, sethamB] = useState(false);
  const hamburgerHandler = () => {
    sethamB((prev) => !prev);
  };
  const [loading, setLoading] = useState(false);
  const form = useRef();
  const success = () => {
    Modal.success({
      content: "Thank you for contacting us!, We will get back to you soon!",
    });
  };

  const sendEmail = (e) => {
    console.log("it is here");
    setLoading(true);
    e.preventDefault();

    emailjs
      .sendForm(
        "service_q666hnw",
        "template_vi4l4s9",
        form.current,
        "HEFLM5R0QBktF17iR"
      )
      .then(
        (result) => {
          console.log(result.text);
          setLoading(false);
          success();
        },
        (error) => {
          setLoading(false);
          console.log(error.text);
        }
      );
  };
  return (
    <>
      <div>
        <header>
          <nav className={`${hamB && "open"}`}>
            <div onClick={() => sethamB(false)} className="overlay"></div>
            <div className="flex-sm middle">
              <div className="item">
                <Link to={"/"}>
                  <p className={classes.contact}>Stocks</p>
                </Link>
                <Link to={"/crypto"}>
                  <p className={classes.contact}>Crypto</p>
                </Link>
                <Link to={"/contact"}>
                  <p className={classes.contact}>Contact</p>
                </Link>
              </div>
            </div>
          </nav>
        </header>
        <Row className={classes.navbar}>
          <Col className={classes.hamburger} xs={2}>
            <div
              onClick={hamburgerHandler}
              className={`hamburger ${hamB && "open"} flex center`}
              role="button"
              aria-label="Toggle menu"
            >
              <div></div>
            </div>
          </Col>
          <Col className={classes.navlogo}>
            <Link to={"/"}>
              <div>
                <img src="/navlogo.png" alt="" className={classes.logo} />
              </div>
            </Link>
            <div className={classes.listbar}>
              <Link to={"/"}>
                <p className={classes.contact}>Stocks</p>
              </Link>
              <Link to={"/crypto"}>
                <p className={classes.contact}>Crypto</p>
              </Link>
              <Link to={"/contact"}>
                <p className={classes.contact}>Contact</p>
              </Link>
            </div>
          </Col>
        </Row>
        <Row className={classes.contactSection}>
          <div className={classes.innerContact}>
            <p className={classes.contactTitle}>Contact Us</p>
            <div className={classes.innerSection}>
              <form
                className={classes.inputSection}
                ref={form}
                onSubmit={sendEmail}
              >
                <Input
                  name="user_name"
                  className={classes.input}
                  placeholder="Your Name*"
                />
                <Input
                  name="user_email"
                  className={classes.input}
                  placeholder="Your Email*"
                />
                <Input
                  name="phone"
                  className={classes.input}
                  placeholder="Your Phone"
                />
                <TextArea
                  className={classes.textarea}
                  placeholder="Message*"
                  name="message"
                  rows={6}
                />
                <div className={classes.buttonDiv}>
                  <Button
                    htmlType="submit"
                    loading={loading}
                    type="submit"
                    className={classes.button}
                  >
                    {loading ? "Sending..." : "Send Message"}
                  </Button>
                </div>
              </form>
              <div className={classes.imagediv}>
                <img className={classes.image} src="/contactus.png" alt="" />
              </div>
            </div>
          </div>
        </Row>
      </div>
      <Footer />
    </>
  );
};

export default Contact;
